import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Layout from 'Layout'
import SEO from 'SEO'
import { HTMLContent } from 'Content'

import View from './ShowView'

class Show extends Component {
  getPatreonUserId () {
    const { show } = this.props
    if (show.frontmatter && show.frontmatter.patreonUserId) {
      return show.frontmatter.patreonUserId
    }

    return false
  }

  render () {
    const { show, episodes, relatedShows, slug } = this.props

    const { frontmatter, html } = show

    /**
     * Check whether we need a Patreon embed
     */
    const patreonUserId = this.getPatreonUserId()

    /**
     * Get the shortDescription
     * If there isn't one, get the first paragraph from the main description
     * @todo make the `html.split(`\n`)[0]` a bit more resilient
     */
    const description = frontmatter.shortDescription || html.split(`\n`)[0].replace(/<[^>]*>?/gm, '')

    return (
      <Layout>
        <SEO
          title={frontmatter.title}
          description={description}
          image={`${show.frontmatter.artwork}-/resize/1000x/social.jpg`}
          slug={slug}
        />
        <View
          {...frontmatter}
          content={html}
          contentComponent={HTMLContent}
          episodes={episodes}
          relatedShows={relatedShows}
          patreonUserId={patreonUserId}
          slug={slug}
        />
      </Layout>
    )
  }
}

Show.propTypes = {
  show: PropTypes.object.isRequired,
  episodes: PropTypes.array.isRequired
}

export default Show
