import React from 'react'

import { ReactComponent as TwitterIcon } from 'assets/img/svg/twitter.svg'

const ItemWithTwitterLink = ({ twitterHandle, name }) => (
  <a
    href={`https://twitter.com/${twitterHandle}`}
    target='_blank'
    rel='noopener noreferrer'
  >
    {name}
    <TwitterIcon />
  </a>
)

const PresenterRollItem = ({ twitterHandle, name }) => {
  return (
    <li>
      {
        twitterHandle
          ? <ItemWithTwitterLink
            name={name}
            twitterHandle={twitterHandle}
          />
          : name
      }
    </li>
  )
}

export default PresenterRollItem
