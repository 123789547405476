import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import sanitizer from 'utilities/sanitizer'
import getRelatedShows from 'RelatedShows/_getRelatedShows'

import Show from 'Show'

const ShowTemplate = ({ data, ...otherProps }) => {
  const {
    show,
    episodes,
    otherShows
  } = data

  const { edges: episodeEdges } = episodes

  // Good naming of the object keys there Gatsby
  const slug = `/${otherProps['*']}`

  const getCategories = () => (
    show.frontmatter.categories.map(({ frontmatter }) => frontmatter.title)
  )

  const relatedShows = getRelatedShows(sanitizer(otherShows.edges), getCategories())

  return (
    <Show
      show={show}
      relatedShows={relatedShows}
      episodes={sanitizer(episodeEdges)}
      slug={slug}
    />
  )
}

ShowTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default ShowTemplate

export const ShowQuery = graphql`
  query (
    $id: String!
    $title: String!
  ) {
    show: markdownRemark(
      id: {
        eq: $id
      }
    ) {
      id
      html
      frontmatter {
        title
        artwork
        featureImage {
          default
          mobile
        }
        awards
        categories {
          frontmatter {
            title
            hex
          }
        }
        shortDescription
        rssFeedUrl
        patreonUserId
        hex
        hideRssShareEpisodes
        bettingPartner
        feeds {
          type
          url
        }
        presenters {
          presenter {
            frontmatter {
              title
              twitterHandle
            }
          }
        }
        producers {
          producer {
            frontmatter {
              title
              twitterHandle
            }
          }
        }
        socials {
          facebookUrl
          twitterUrl
          instagramUrl
        }
      }
    }
    otherShows: allMarkdownRemark(
      filter: {
        id: {
          ne: $id
        }
        frontmatter: {
          templateKey: {
            regex: "/ShowTemplate/"
          }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            artwork
            categories {
              frontmatter {
                title
                hex
              }
            }
          }
        }
      }
    }
    episodes: allMarkdownRemark(
      filter: {
        frontmatter: {
          show: {
            frontmatter: {
              title: {
                eq: $title
              }
            }
          }
          showInRecentEpisodes: {
            eq: true
          }
          templateKey: {
            regex: "/EpisodeTemplate/"
          }
        }
      }
      sort: {
        fields: [frontmatter___publishDate]
        order: DESC
      }
      #limit: 10
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            audioUrl
            audioDuration
            publishDate(formatString: "MMMM D, YYYY")
            show {
              fields {
                slug
              }
              frontmatter {
                title
                artwork
              }
            }
          }
        }
      }
    }
  }
`
