import React from 'react'
import PropTypes from 'prop-types'

import View from './SocialRollView'

const SocialRoll = ({ urls }) => <View urls={urls} />

SocialRoll.propTypes = {
  urls: PropTypes.object.isRequired
}

export default SocialRoll
