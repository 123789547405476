import React from 'react'
import PropTypes from 'prop-types'
import Image from 'Image'
import SocialShare from 'SocialShare'

import Feeds from './ShowFeeds'
import FeatureImage from './ShowFeatureImage'

const ShowHeaderExtras = ({ patreonUserId, bettingPartner, awards }) => {
  if (patreonUserId || bettingPartner || (awards && awards.length > 0)) {
    return (
      <div className='u-margin-top-small'>
        <div className='c-show-hero__extras'>
          {
            patreonUserId &&
            <span
              className='c-show-hero__pateron u-dis-b'
            >
              {/* eslint-disable-next-line */}
              <iframe
                src={`https://www.patreon.com/platform/iframe?widget=become-patron-button&redirectURI=https%3A%2F%2Fstak.london%2Fshows%2Fthe-football-ramble%2F&creatorID=${patreonUserId}&openInNewTab=true`}
                scrolling='no'
                allowtransparency='true'
                frameBorder='0'
                className='patreon-widget'
                title='Patreon Widget'
                style={{ position: 'static', visibility: 'visible', width: '176px', height: '34px' }}
              />
            </span>
          }
          {
            bettingPartner &&
            <span
              className='c-show-hero__sponsor'
            >
              Official Betting Partner <Image image={bettingPartner} width={240} />
            </span>
          }
          {
            (awards && awards.length > 0) && (
              <span className='c-show-hero__awards'>
                {awards.map((award) => {
                  return (
                    <span
                      key={award}
                      className='c-show-hero__award'
                    >
                      <Image
                        image={award}
                        width={100}
                        sizes={[
                          '100px'
                        ]}
                        alt=''
                      />
                    </span>
                  )
                })}
              </span>
            )
          }
        </div>
      </div>
    )
  }

  return null
}

const ShowHeader = ({ title, artwork, featureImage, shortDescription, feeds, rssFeedUrl, patreonUserId, hex, slug, bettingPartner, hideRssShareEpisodes, awards }) => (
  <header
    className='c-show-hero'
  >
    <div
      className='o-container--thin'
    >
      <div
        className='c-show-hero__grid c-show-hero__content'
      >
        <figure
          className='c-show-hero__figure u-img-full'
        >
          <Image
            image={artwork}
            width={684}
            sizes={[
              `${Image.breakpoint('md')} calc(100vw - 54px)`,
              `${Image.breakpoint('container')} calc((100vw - 54px) / 3)`,
              '420px'
            ]}
            alt={`The artwork for the ${title} podcast.`}
          />
        </figure>
        <div
          className='c-show-hero__content'
        >
          <h2
            className='c-show-hero__title u-margin-bottom-small'
          >
            {title}
          </h2>
          <p
            className='u-color-concrete'
          >
            {shortDescription}
          </p>
          {!hideRssShareEpisodes && (
            <div
              className='c-show-hero__share u-margin-bottom'
            >
              <SocialShare
                title={title}
                slug={slug}
                shape='oval'
              />
            </div>
          )}
          <Feeds
            feeds={feeds}
            rssFeedUrl={rssFeedUrl}
            hideRssShareEpisodes={hideRssShareEpisodes}
          />
          <ShowHeaderExtras
            patreonUserId={patreonUserId}
            bettingPartner={bettingPartner}
            awards={awards}
          />
        </div>
      </div>
    </div>
    <FeatureImage
      image={featureImage}
      alt={`The feature image for the ${title} podcast.`}
      backgroundColor={hex}
    />
  </header>
)

ShowHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default ShowHeader
