import shuffle from 'lodash.shuffle'
import reject from 'lodash.reject'

/**
 * Find related shows, based on the Show's category(s)
 */
const getRelatedShows = (shows, matchingCategories) => {
  /* Shuffle the shows */
  let allShows = shuffle(shows)

  /* Returns the first matching show (based on categories) */
  const getFirstRelatedShow = () => (
    allShows.find(({ categories }) => {
      const array = categories.map(({ frontmatter }) => frontmatter.title)
      return array.find(category => matchingCategories.includes(category))
    })
  )

  /* Returns a random show from the array */
  const getRandomShow = () => (
    allShows[Math.floor(Math.random() * allShows.length)]
  )

  // Get a related show
  let relatedShow = getFirstRelatedShow()
  if (!relatedShow) {
    relatedShow = getRandomShow()
  }

  // Remove the related show from the array
  allShows = reject(allShows, relatedShow)

  // Get a random show
  const randomShow = getRandomShow()

  return [relatedShow, randomShow]
}

export default getRelatedShows
