import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ShowRollItem from './ShowRollItem'

class ShowRollLoop extends Component {
  render () {
    const { shows, classNamePrefix, lazyload, layout } = this.props

    return shows.map((show) => (
      <ShowRollItem
        key={show.slug}
        classNamePrefix={classNamePrefix}
        lazyload={lazyload}
        layout={layout}
        {...show}
      />
    ))
  }
}

ShowRollLoop.propTypes = {
  shows: PropTypes.array.isRequired,
  classNamePrefix: PropTypes.string,
  lazyload: PropTypes.bool,
  layout: PropTypes.string
}

ShowRollLoop.defaultProps = {
  layout: 'grid'
}

export default ShowRollLoop
