import React from 'react'
import PropTypes from 'prop-types'

import Item from './PresenterRollItem'

const PresenterRollView = ({ items }) => {
  return (
    <ul
      className='o-cleanlist'
    >
      {
        items.map((item, key) => {
          if (!item) {
            return null
          }

          return (
            <Item
              key={key}
              name={item.title}
              twitterHandle={item.twitterHandle}
            />
          )
        })
      }
    </ul>
  )
}

PresenterRollView.propTypes = {
  items: PropTypes.array.isRequired
}

export default PresenterRollView
