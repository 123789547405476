import React from 'react'
import PropTypes from 'prop-types'

import View from './CategoriesRollView'

const CategoriesRoll = ({ categories }) => (
  <View
    categories={categories}
  />
)

CategoriesRoll.propTypes = {
  categories: PropTypes.array.isRequired
}

export default CategoriesRoll
