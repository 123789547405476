import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Image from 'Image'

class ShowRollItem extends Component {
  renderImage ({ artwork, title, layout, lazyload }) {
    if (layout === 'related') {
      return (
        <Image
          image={artwork}
          width={738}
          alt={`Artwork for the ${title} podcast.`}
          sizes={[
            `${Image.breakpoint('md')} calc(100vw - 54px)`,
            `${Image.breakpoint('lg')} calc(((100vw - 54px) / 2) - 82px)`,
            `${Image.breakpoint('lg')} calc(((100vw - 54px) / 2) - 136px)`,
            `${Image.breakpoint('xl')} calc(((100vw - 54px) / 2) - 216px)`,
            `${Image.breakpoint('lg')} calc(((100vw - 54px) / 4) - 196px)`,
            '414px'
          ]}
          minWidth={100}
          lazyload={lazyload}
        />
      )
    }

    return (
      <Image
        image={artwork}
        width={630}
        alt={`Artwork for the ${title} podcast.`}
        sizes={[
          `${Image.breakpoint('md')} calc(100vw - 108px)`,
          `${Image.breakpoint('lg')} calc(((100vw - 54px) / 4) - 54px)`,
          `${Image.breakpoint('container')} calc(((100vw - 54px) / 4) - 196px)`,
          '226px'
        ]}
        minWidth={100}
        lazyload={lazyload}
      />
    )
  }

  render () {
    const { classNamePrefix, lazyload, layout, ...show } = this.props
    /* Deconstruct the episode object */
    const {
      slug,
      artwork,
      title
    } = show

    return (
      <article
        className={`${classNamePrefix}-item`}
      >
        <Link
          to={slug}
          key={slug}
          className={`${classNamePrefix}-item__link o-action-link__parent`}
        >
          {
            artwork &&
            <figure
              className={`${classNamePrefix}-item__image u-img-full`}
            >
              { this.renderImage({ artwork, title, layout, lazyload }) }
            </figure>
          }
          <h4
            className={`${classNamePrefix}-item__title o-action-link--black`}
          >
            { title }
          </h4>
        </Link>
      </article>
    )
  }
}

ShowRollItem.propTypes = {
  classNamePrefix: PropTypes.string
}

ShowRollItem.defaultProps = {
  classNamePrefix: `c-show-roll-grid`
}

export default ShowRollItem
