import React from 'react'
import PropTypes from 'prop-types'
import Image from 'Image'

import Content from 'Content'
import CategoriesRoll from 'CategoriesRoll'
import PresenterRoll from 'PresenterRoll'
import SocialRoll from 'SocialRoll'
import EpisodeRoll from 'EpisodeRoll'
import RelatedShows from 'RelatedShows'
import SocialIcon from 'SocialIcons/SocialIconsItem'

import Header from './ShowHeader'
import Sidebar, { SidebarItem } from 'Sidebar'
import Main from './ShowMain'

const ShowView = ({ episodes, content, contentComponent, relatedShows, ...props }) => {
  const ShowContent = contentComponent || Content

  const {
    title,
    feeds,
    presenters,
    categories,
    socials,
    artwork,
    shortDescription,
    featureImage,
    featureImageMobile,
    rssFeedUrl,
    patreonUserId,
    bettingPartner,
    hex,
    producers,
    slug,
    hideRssShareEpisodes,
    awards
  } = props

  return (
    <article
      className='c-show'
    >
      <Header
        title={title}
        artwork={artwork}
        description={content}
        feeds={feeds}
        featureImage={featureImage}
        featureImageMobile={featureImageMobile}
        shortDescription={shortDescription}
        rssFeedUrl={rssFeedUrl}
        patreonUserId={patreonUserId}
        bettingPartner={bettingPartner}
        hex={hex}
        slug={slug}
        hideRssShareEpisodes={hideRssShareEpisodes}
        awards={awards}
      />
      {
        (episodes && !hideRssShareEpisodes) &&
          <EpisodeRoll episodes={episodes} />
      }
      <div className='c-show-main'>
        <div
          className='o-container--thin'
        >
          <div
            className='c-show-grid'
          >
            <Sidebar
              className='c-show-grid-sidebar'
            >
              <figure
                className='u-img-full u-margin-bottom'
              >
                <Image
                  image={artwork}
                  width={684}
                  minWidth={100}
                  sizes={[
                    `${Image.breakpoint('md')} calc(100vw - 54px)`,
                    `${Image.breakpoint('container')} calc(((100vw - 54px) / 5) - 41px)`,
                    '184px'
                  ]}
                  alt={`The artwork for the ${title} podcast.`}
                  lazyload
                />
              </figure>
              <SidebarItem
                label='Name'
              >
                <p>{title}</p>
              </SidebarItem>
              {
                (presenters && presenters.length > 0) &&
                  <SidebarItem
                    label='Presenters'
                  >
                    <PresenterRoll
                      items={presenters.map(({ presenter }) => {
                        if (!presenter) {
                          return null
                        }

                        const { frontmatter } = presenter
                        const { title, twitterHandle } = frontmatter

                        return { title, twitterHandle }
                      })}
                    />
                  </SidebarItem>
              }
              {
                (producers && producers.length) &&
                  <SidebarItem
                    label='Producers'
                  >
                    <PresenterRoll
                      items={producers.map(({ producer }) => {
                        if (!producer) {
                          return null
                        }

                        const { frontmatter } = producer
                        const { title, twitterHandle } = frontmatter

                        return { title, twitterHandle }
                      })}
                    />
                  </SidebarItem>
              }
              {
                (socials && socials.length) &&
                  <SidebarItem
                    label='Socials'
                  >
                    <SocialRoll urls={socials} />
                  </SidebarItem>
              }
              {
                (categories && categories.length) &&
                  <SidebarItem
                    label='Categories'
                  >
                    <CategoriesRoll categories={categories.map(({ frontmatter }) => frontmatter)} />
                  </SidebarItem>
              }
            </Sidebar>
            <Main>
              <h2>About the show</h2>
              <ShowContent
                content={content}
                className='c-show-grid-main__body'
              />
              {
                socials &&
                  <div className='c-show-social u-margin-top'>
                    <h3 className='c-show-social__label u-dis-ib u-fs-14 u-margin-right-small'>Follow</h3>
                    <ul className='c-show-social-follow u-dis-ib o-list-inline'>
                      {
                      Object.keys(socials).map((key, index) =>
                        socials[key] &&
                          <li
                            className='c-show-social-follow__item u-align-middle u-color-black'
                            key={index}
                          >
                            <SocialIcon
                              linkTo={socials[key]}
                              service={key.replace('Url', '')}
                              className='c-social-icon--no-border c-social-icon--sm u-bgcolor-black u-margin-left-none u-margin-right-none'
                            />
                          </li>
                      )
                    }
                    </ul>
                  </div>
              }
            </Main>
          </div>
        </div>
      </div>
      {
        relatedShows &&
        relatedShows.length > 0 &&
          <RelatedShows
            shows={relatedShows}
          />
      }
    </article>
  )
}

ShowView.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  episodes: PropTypes.array
}

export default ShowView
