import React from 'react'

const ShowMain = ({ children }) => (
  <div
    className='c-show-grid-main'
  >
    {children}
  </div>
)

export default ShowMain
