import React from 'react'
import Image from 'Image'

const ShowFeatureImage = ({ image, alt, backgroundColor }) => (
  <div
    className='c-show-hero__image'
    style={{ backgroundColor: `${backgroundColor}` }}
  >
    {
      image &&
      <Image
        image={image.default}
        mobileImage={image.mobile}
        width={2000}
        sizes='100vw'
        alt={alt}
        className='o-fill-parent'
      />
    }
  </div>
)

export default ShowFeatureImage
