import React from 'react'
import PropTypes from 'prop-types'

const SocialRollView = ({ urls }) => (
  <ul
    className='o-cleanlist'
  >
    {
      Object.keys(urls).map((key, index) =>
        urls[key] &&
          <li
            key={index}
          >
            <a
              href={urls[key]}
              target='_blank'
              rel='noopener noreferrer'
              className='u-tt-capitalize'
            >
              {urls[key].split('https://')[1].split('.com')[0]}
            </a>
          </li>
      )
    }
  </ul>
)

SocialRollView.propTypes = {
  urls: PropTypes.object.isRequired
}

export default SocialRollView
