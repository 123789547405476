import React from 'react'

const SidebarView = ({ children, className }) => (
  <div
    className={`c-sidebar ${className}`}
  >
    {children}
  </div>
)

export default SidebarView
