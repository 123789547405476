import React from 'react'
import PropTypes from 'prop-types'

const CategoriesRollView = ({ categories }) => (
  <ul
    className='o-cleanlist'
  >
    {
      categories.map(({ title, hex }, key) =>
        <li
          key={key}
          className='c-tag u-margin-bottom-tiny'
          style={{
            backgroundColor: hex
          }}
        >
          {title}
        </li>
      )
    }
  </ul>
)

CategoriesRollView.propTypes = {
  categories: PropTypes.array.isRequired
}

export default CategoriesRollView
