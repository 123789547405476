import React from 'react'

const SidebarItem = ({ label, children }) => (
  <span
    className='c-sidebar-item'
  >
    <h4
      className='c-sidebar-item__label'
    >
      {label}
    </h4>
    {children}
  </span>
)

export default SidebarItem
