import React from 'react'
import PropTypes from 'prop-types'

import View from './PresenterRollView'

const PresenterRoll = ({ items }) => {
  return (
    <View items={items} />
  )
}

PresenterRoll.propTypes = {
  items: PropTypes.array.isRequired
}

export default PresenterRoll
