const sanitizer = (data) => {
  const output = data.map(({ node }) => {
    const { frontmatter, fields } = node
    const sanitized = Object.assign(frontmatter, fields)

    if (node.excerpt) {
      sanitized.excerpt = node.excerpt
    }

    return sanitized
  })

  return output
}

export default sanitizer
