import React from 'react'
import renderOnClient from 'utilities/renderOnClient'

import { ReactComponent as MicrophoneIcon } from 'assets/img/svg/microphone.svg'

import ShowRollLoop from 'ShowRoll/ShowRollLoop'

// Only render on client-side, as the shows are random, so the server-side
// doesn't always match up to the client-side
const ClientSideShowRollLoop = renderOnClient(ShowRollLoop)

const RelatedShowsView = ({ shows }) => (
  <div
    className='c-related-shows u-ta-c'
  >
    <div
      className='o-container--thin u-border-top u-margin-top-large u-margin-top-huge@md u-padding-top-large u-padding-bottom-large u-padding-top-huge@md u-padding-bottom-huge@md'
    >
      <span
        className='c-related-shows__icon u-dis-b u-margin-bottom-small'
      >
        <MicrophoneIcon />
      </span>
      <h4
        className='e-h3 u-margin-bottom-large@md'
      >
        You may also like
      </h4>
      <div
        className='c-related-shows-grid'
      >
        <ClientSideShowRollLoop
          shows={shows}
          classNamePrefix='c-related-shows-grid'
          layout='related'
          lazyload
        />
      </div>
    </div>
  </div>
)

export default RelatedShowsView
