import React from 'react'

import SidebarView from './SidebarView'
import SidebarItem from './SidebarItem'

const Sidebar = ({ children, className }) => (
  <SidebarView
    className={className}
  >
    {children}
  </SidebarView>
)

export { Sidebar as default, SidebarItem }
